import type {IGallerySantaProps, IPropsInjectedByViewerScript} from '../types/galleryTypes';
import {IHostProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IStylesContextValue} from '@wix/tpa-settings';
import {IStylesParams} from '../styleParams/types';

type ProvidedGlobalPropNames =
  | 'allowFreeProducts'
  | 'addedToCartStatus'
  | 'clearFilters'
  | 'currentPage'
  | 'scrollToProduct'
  | 'totalPages'
  | 'maxProductsPerPage'
  | 'linkForAllPages'
  | 'nextPrevLinks'
  | 'experiments'
  | 'filterModels'
  | 'clearScrollToProduct'
  | 'filterProducts'
  | 'applyFilteredProductsOnMobile'
  | 'focusedProductIndex'
  | 'getCategoryProducts'
  | 'handleAddToCart'
  | 'sendSortClickBiEvent'
  | 'sendClickShippingInfoLinkSf'
  | 'handleCategoryClick'
  | 'handleCategoryBreadcrumbsClick'
  | 'handleCategoryClampClick'
  | 'handlePagination'
  | 'reportProductItemClick'
  | 'handleProductItemClick'
  | 'handleProductsOptionsChange'
  | 'hasMoreProductsToLoad'
  | 'hasSelectedFilters'
  | 'htmlTags'
  | 'host'
  | 'imagePosition'
  | 'imageMode'
  | 'imageRatio'
  | 'isAutoGrid'
  | 'isCategoryPage'
  | 'isFirstPage'
  | 'isHorizontalLayout'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isPreviewMode'
  | 'isGalleryRowsAndColsWereModified'
  | 'shouldShowMobile'
  | 'isCategoryVisible'
  | 'isEditorMode'
  | 'isRTL'
  | 'isSEO'
  | 'isSSR'
  | 'loadMoreProducts'
  | 'loadMoreType'
  | 'loading'
  | 'mainCollectionId'
  | 'numberOfSelectedFilterTypes'
  | 'openQuickView'
  | 'paginationMode'
  | 'priceBreakdown'
  | 'productsManifest'
  | 'products'
  | 'productsVariantInfoMap'
  | 'productsPriceRangeMap'
  | 'selectedSort'
  | 'shouldAlternateImagePosition'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'shouldShowClearFilters'
  | 'shouldShowMobileFiltersModal'
  | 'shouldShowSort'
  | 'shouldShowImageCarousel'
  | 'shouldShowProductOptions'
  | 'shouldUseAutoGridProductsCount'
  | 'showShowLightEmptyState'
  | 'setSelectedSort'
  | 'sortProducts'
  | 'sortingOptions'
  | 'textsMap'
  | 'toggleFiltersModalVisibility'
  | 'totalProducts'
  | 'updateAddToCartStatus'
  | 'productsRequestInProgress'
  | 'currentCategory'
  | 'categories'
  | 'breadcrumbsHistory'
  | 'isOptionsRevealEnabled'
  | 'sortingOptionsWithoutDefault'
  | 'mobileFiltersPanelState'
  | 'allProductsCategoryId';

export type IGlobals = Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
  styles: IStylesContextValue;
  stylesParams: IStylesParams;
} & Pick<IHostProps, 'updateLayout' | 'dimensions' | 'appLoadBI' | 'accessibilityEnabled'> & {compId: string};

export interface IGalleryGlobalProps {
  globals?: IGlobals;
}

export const galleryGlobalsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    allowFreeProducts,
    addedToCartStatus,
    clearFilters,
    currentPage,
    scrollToProduct,
    totalPages,
    maxProductsPerPage,
    linkForAllPages,
    nextPrevLinks,
    experiments,
    filterModels,
    clearScrollToProduct,
    filterProducts,
    applyFilteredProductsOnMobile,
    focusedProductIndex,
    getCategoryProducts,
    handleAddToCart,
    sendClickShippingInfoLinkSf,
    sendSortClickBiEvent,
    handleCategoryClick,
    handleCategoryBreadcrumbsClick,
    handleCategoryClampClick,
    handlePagination,
    reportProductItemClick,
    handleProductItemClick,
    handleProductsOptionsChange,
    hasMoreProductsToLoad,
    hasSelectedFilters,
    htmlTags,
    imagePosition,
    imageMode,
    imageRatio,
    isAutoGrid,
    isCategoryPage,
    isFirstPage,
    isHorizontalLayout,
    isInteractive,
    isLiveSiteMode,
    isPreviewMode,
    shouldShowMobile,
    isCategoryVisible,
    isEditorMode,
    isRTL,
    isSEO,
    isSSR,
    isGalleryRowsAndColsWereModified,
    loadMoreProducts,
    loadMoreType,
    loading,
    mainCollectionId,
    numberOfSelectedFilterTypes,
    openQuickView,
    paginationMode,
    priceBreakdown,
    productsManifest,
    products,
    productsVariantInfoMap,
    productsPriceRangeMap,
    selectedSort,
    shouldAlternateImagePosition,
    shouldShowAddToCartSuccessAnimation,
    shouldShowClearFilters,
    shouldShowMobileFiltersModal,
    shouldUseAutoGridProductsCount,
    shouldShowSort,
    showShowLightEmptyState,
    shouldShowImageCarousel,
    shouldShowProductOptions,
    setSelectedSort,
    sortProducts,
    sortingOptions,
    textsMap,
    toggleFiltersModalVisibility,
    totalProducts,
    updateAddToCartStatus,
    productsRequestInProgress,
    isOptionsRevealEnabled,
    currentCategory,
    breadcrumbsHistory,
    categories,
    sortingOptionsWithoutDefault,
    mobileFiltersPanelState,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout, id: compId, accessibilityEnabled} = props.host;
  return {
    globals: {
      accessibilityEnabled,
      allowFreeProducts,
      addedToCartStatus,
      appLoadBI,
      clearFilters,
      currentPage,
      scrollToProduct,
      totalPages,
      maxProductsPerPage,
      linkForAllPages,
      nextPrevLinks,
      compId,
      dimensions,
      experiments,
      filterModels,
      clearScrollToProduct,
      filterProducts,
      applyFilteredProductsOnMobile,
      focusedProductIndex,
      getCategoryProducts,
      handleAddToCart,
      sendSortClickBiEvent,
      sendClickShippingInfoLinkSf,
      handleCategoryClick,
      handleCategoryBreadcrumbsClick,
      handleCategoryClampClick,
      handlePagination,
      reportProductItemClick,
      handleProductItemClick,
      handleProductsOptionsChange,
      hasMoreProductsToLoad,
      hasSelectedFilters,
      host: props.host,
      htmlTags,
      imagePosition,
      imageMode,
      imageRatio,
      isAutoGrid,
      isCategoryPage,
      isFirstPage,
      isHorizontalLayout,
      isInteractive,
      isLiveSiteMode,
      isPreviewMode,
      shouldShowMobile,
      isCategoryVisible,
      isEditorMode,
      isRTL,
      isSEO,
      isSSR,
      isGalleryRowsAndColsWereModified,
      loadMoreProducts,
      loadMoreType,
      loading,
      mainCollectionId,
      numberOfSelectedFilterTypes,
      openQuickView,
      paginationMode,
      priceBreakdown,
      productsManifest,
      products,
      productsVariantInfoMap,
      productsPriceRangeMap,
      selectedSort,
      shouldAlternateImagePosition,
      shouldShowAddToCartSuccessAnimation,
      shouldShowClearFilters,
      shouldShowMobileFiltersModal,
      shouldShowSort,
      shouldUseAutoGridProductsCount,
      showShowLightEmptyState,
      shouldShowImageCarousel,
      shouldShowProductOptions,
      setSelectedSort,
      sortProducts,
      sortingOptions,
      textsMap,
      toggleFiltersModalVisibility,
      totalProducts,
      updateLayout,
      updateAddToCartStatus,
      productsRequestInProgress,
      isOptionsRevealEnabled,
      currentCategory,
      breadcrumbsHistory,
      categories,
      sortingOptionsWithoutDefault,
      mobileFiltersPanelState,
    },
    locals,
  };
};
